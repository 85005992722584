import React, { useEffect, useRef, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import {
  Container,
  ContentWithPaddingLg,
  ContentWithPaddingMd,
  EmptyStyledDiv,
  SimplePadding,
  StyledDiv,
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Footer from "components/footers/SimpleFiveColumn";
import { navLinks } from "components/hero/CISNavLinks";
import "suneditor/dist/css/suneditor.min.css";
import "../../src/styles/customStyles.css";
import ButtonsWithPermissions from "components/misc/ButtonsWithPermissions";

import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "components/misc/Buttons";
import { AltEditor } from "engine/AltEditor";
import { SunEditorComponent } from "engine/SunEditor";
import { Helmet } from "react-helmet";
import ProfileSection from "components/misc/ProfileSection";
import { DatabaseMemoUpdater } from "engine/DatabaseMemoUpdater";
import { showNotification } from "@mantine/notifications";
import "./Reset.css";
import { HtmlShower } from "./HtmlShower";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { AboutTheAuthor } from "CMS/accounts/AboutTheAuthor";
import { deleteDoc } from "firebase/firestore";
import { capitalizeFirstLetter, getDateFromTSOrDateOrStr } from "CMS/logic/HelperFunctions";

const OrderButton = styled(PrimaryButton)`
  ${tw`h-14`}
`;
const OrderSection = tw.div`w-96 mx-auto flex justify-between gap-4 p-4 border-2 rounded-lg`;
const OrderImg = tw.img`rounded-lg w-40 h-56`;
const OrderTitle = tw.p`font-bold text-lg text-gray-800 leading-loose`;
const OrderRow = tw.div`flex justify-between flex-col`;
const TopRow = tw.div`flex  w-full justify-between z-50 cursor-pointer  items-center px-4 text-sky-400 -mb-8`;
const HeadingRow = tw.div`flex px-4`;
const FT = tw.p`z-50  justify-self-start`;
const LT = tw.p`z-50 justify-self-end`;

const Heading = tw.h1`text-gray-900 text-4xl sm:text-5xl  text-black tracking-wide text-center `;
const DagboekHeading = tw.h2`text-blue-400 text-3xl sm:text-4xl tracking-wide text-center -mt-8`;
const DagboekHeadingLink = tw.a`cursor-pointer hover:text-blue-700`;
export const Text = styled.div`
  ${tw`text-lg  text-gray-800 my-10 leading-loose`}
  p {
    ${tw`leading-loose`}
    margin-top: 1.15rem;
  }
  br {
    ${tw`mt-2 leading-loose`}
  }
  span {
    ${tw`mt-0 leading-loose`}
  }
  a {
    ${tw`mt-2 leading-loose text-blue-500`}
  }
  h2 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h3 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h4 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const NameDesc = styled.div`
  ${tw`text-sm text-gray-700 px-4 gap-2 md:px-8 pt-4 flex justify-between `}
  p {
    ${tw`mt-2 text-gray-500`}
  }
  h3 {
    ${tw`text-xl font-bold`}
  }
`;

export default (props) => {
  const history = useNavigate();

  // Resort the database when deleting
  const [showMemoUpdater, setShowMemoUpdater] = useState(false);
  const [catsSetAfterDeleteAction, setCatsSetAfterDeleteAction] = useState();

  //Read params from url for article id and open mode
  const { slugpart, mode } = useParams();

  const loadArticle = async () => {
    if (slugpart && props.firestore) {
      //Load DB content by current active tab

      const docRef = query(
        collection(props.firestore, "documents"),
        where("slug", "==", slugpart)
      );
      console.log("trying to load doc", docRef, slugpart);
      const articlesTemp = await getDocs(docRef);
      console.log("articlesTemp", articlesTemp.docs);
      const articleTemp = articlesTemp.docs[0];
      setArticle(articleTemp);
    }
  };

  useEffect(() => {
    loadArticle();
  }, [slugpart, props.firestore]);

  const [article, setArticle] = useState();

  const altEditorRef = useRef(null);

  return (
    <div>
      {showMemoUpdater && (
        <DatabaseMemoUpdater
          categories={catsSetAfterDeleteAction}
          close={() => setShowMemoUpdater(false)}
          maxBatchDoc={props.metadataDoc && props.metadataDoc}
          firestore={props.firestore}
          firebase={props.firebase}
        ></DatabaseMemoUpdater>
      )}
      <Helmet>
        <title>{article && article.data() ? article.data().title : null}</title>
        <meta
          name="description"
          //Set the start of the article as the google description.
          content={
            article && article.data()
              ? article.data().htmlfreetext &&
                article.data().htmlfreetext.slice(0, 200)
              : null
          }
        />
      </Helmet>
      {props.navbar}
      <Container>
        <ContentWithPaddingMd>
          <div>
            <Text>
              {mode === "edit" && article && (
                <>
                  <AltEditor
                    mode={mode}
                    firestore={props.firestore}
                    firebase={props.firebase}
                    user={props.user}
                    history={history}
                    userData={props.userData}
                    ref={altEditorRef}
                    functions={props.functions}
                    metadataDoc={props.metadataDoc}
                  ></AltEditor>
                </>
              )}
              {mode !== "edit" && article && article.data() && (
                <div>
                  {article.data().categoryMap && (
                    <DagbboekPointer
                      metadataDoc={props.metadataDoc}
                      article={article}
                    ></DagbboekPointer>
                  )}
                  <HeadingRow>
                    <Heading>{article.data().title}</Heading>
                  </HeadingRow>
                  <NameDesc>
                    {article.data().visible && (
                      <p>
                        {article.data().showAuthor && (
                          <>
                            {article.data().authorName} {" · "}
                          </>
                        )}
                        {article.data().showPublicist && (
                          <>
                            {"gepubliceerd door: " +
                              article.data().publicistName}{" "}
                            {" · "}
                          </>
                        )}
                        {/* Use custom date if set to true */}
                        {article.data().customDate ? getDateFromTSOrDateOrStr(article.data().customDate).toLocaleDateString("nl-NL", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }) + " · "
                          : // otherwise check for creationdate and use it
                            article.data().creationDate &&
                            article.data().creationDate.toDate() &&
                            article
                              .data()
                              .creationDate.toDate()
                              .toLocaleDateString("nl-NL", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              }) + " · "}
                        {article.data().lastEditDate &&
                          article.data().lastEditDate.toDate() &&
                          "laatst bewerkt: " +
                            article
                              .data()
                              .lastEditDate.toDate()
                              .toLocaleDateString("nl-NL", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              }) +
                            " · "}
                        {article.data().htmlfreetext && (
                          <>
                            {""}
                            {Math.ceil(
                              article.data().htmlfreetext.length / 1081
                            )}{" "}
                            minuten lezen
                          </>
                        )}
                      </p>
                    )}
                    <ButtonsWithPermissions
                      userData={props.userData}
                      user={props.user}
                      article={article}
                      editClick={() =>
                        history(
                          "/editpost/" + encodeURIComponent(article.data().slug)
                        )
                      }
                      deleteClick={async () => {
                        // BEFORE deleting the article set the categories that need ot be resorted
                        setCatsSetAfterDeleteAction(article.data().categories);
                        try {
                          await deleteDoc(
                            props.firestore,
                            "documents/" + article.data().id
                          );

                          // Quick filter method for objects
                          Object.filter = (obj, predicate) =>
                            Object.keys(obj)
                              .filter((key) => predicate(obj[key]))
                              .reduce(
                                (res, key) => ((res[key] = obj[key]), res),
                                {}
                              );

                          // If Parasja cat wa removed remove it from the metadata parasjot list
                          var parasjotData =
                            props.metadataDoc.parasjaList || {};
                          if (
                            Object.keys(
                              article.data().categoryMap || {}
                            ).includes("Dagboek bij de najaarsfeesten")
                          ) {
                            parasjotData = Object.filter(
                              parasjotData,
                              (el) => el.objid !== article.data().blogid
                            );

                            await setDoc(
                              doc(props.firestore, "metadata", "batching"),
                              { parasjotData },
                              { merge: true }
                            );
                          }

                          showNotification({
                            id: "delete",
                            title: "Artikel verwijderd ",
                            message: "Succesvol verwijderd! 😉",
                            loading: false,
                            autoClose: true,
                            icon: (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            ),
                            color: "teal",
                            disallowClose: false,
                          });
                          setShowMemoUpdater(true);
                        } catch (error) {
                          console.log("Error deleting", error);
                        }
                      }}
                    ></ButtonsWithPermissions>
                  </NameDesc>
                </div>
              )}
              {mode !== "edit" &&
                article &&
                article.data() &&
                !article.data().visible && (
                  <p>
                    Dit artikel is (nog) niet openbaar. Mogelijk wordt het nog
                    geredacteerd of heb je deze nog niet gepubliceerd.
                  </p>
                )}{" "}
            </Text>

            {mode !== "edit" &&
              article &&
              article.data() &&
              article.data().visible && (
                // <PageContent article={article}></PageContent>
                <>
                  {article.data().pureHtml ? (
                    <HtmlShower html={article.data().text}></HtmlShower>
                  ) : (
                    <div>
                      <img
                        src={article.data().imageURL}
                        alt="Article"
                        className="w-full object-contain"
                      />
                      <SunEditorComponent
                        defaultValue={article.data().text}
                        hideToolbar={true}
                        disable={true}
                        readOnly={false}
                        metadataDoc={props.metadataDoc}
                        firestore={props.firestore}
                      ></SunEditorComponent>
                    </div>
                  )}
                  {article.data().showAuthor && (
                    <AboutTheAuthor state={article.data()} />
                  )}
                </>
              )}
          </div>
          {article &&
            article.data() &&
            article.data().visible &&
            mode !== "edit" &&
            article.data().checkoutUrl &&
            article.data().checkoutUrl !== "" && (
              <OrderSection>
                <OrderImg src={article.data().imageURL}></OrderImg>
                <OrderRow>
                  <OrderTitle>{article.data().title}</OrderTitle>
                  <OrderButton
                    onClick={() =>
                      (window.location.href = article.data().checkoutUrl)
                    }
                  >
                    Bestel boek
                  </OrderButton>
                </OrderRow>
              </OrderSection>
            )}
        </ContentWithPaddingMd>
      </Container>
      <SimplePadding>
        <Footer
          firestore={props.firestore}
          functions={props.functions}
          firebase={props.firebase}
        />
      </SimplePadding>
    </div>
  );
};
export function PageContent(props) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.article.data().text,
      }}
    ></div>
  );
}

const DagbboekPointer = (props) => {
  const [next, setNext] = useState();

  const [prev, setPrev] = useState();
  const history = useNavigate();
  var isDagboek = Object.keys(props.article.data().categoryMap).includes(
    "Dagboek bij de najaarsfeesten"
  );

  useEffect(() => {
    if (!next && !prev) {
      var dagboekDate =
        props.article &&
        props.article.data() &&
        props.article.data().parasjaDate;
      if (dagboekDate) {
        if (dagboekDate.toDate) dagboekDate = dagboekDate.toDate();

        var dateOffset = 24 * 60 * 60 * 1000;

        var datePlus = new Date();
        datePlus.setTime(dagboekDate.getTime() + dateOffset);
        datePlus = datePlus.toISOString().split("T")[0];

        var dateMinus = new Date();
        dateMinus.setTime(dagboekDate.getTime() - dateOffset);
        dateMinus = dateMinus.toISOString().split("T")[0];

        console.log(
          dagboekDate.toISOString().split("T")[0],
          dateMinus,
          datePlus
        );

        if (
          Object.values(props.metadataDoc.parasjotData).some(
            (e) => e.start === dateMinus
          )
        ) {
          setPrev(
            Object.values(props.metadataDoc.parasjotData).find(
              (e) => e.start === dateMinus
            )
          );
        }

        if (
          Object.values(props.metadataDoc.parasjotData).some(
            (e) => e.start === datePlus
          )
        ) {
          setNext(
            Object.values(props.metadataDoc.parasjotData).find(
              (e) => e.start === datePlus
            )
          );
        }
      }
    }
  }, []);

  return (
    isDagboek && (
      <Text>
        <TopRow>
          {
            <FT
              onClick={() => {
                history("/blogs/" + prev.objid);
              }}
            >
              {prev && "< " + prev.title}
            </FT>
          }
          {
            <LT
              onClick={() => {
                history("/blogs/" + next.objid);
              }}
            >
              {next && "> " + next.title}
            </LT>
          }
        </TopRow>
        <DagboekHeading>
          <DagboekHeadingLink
            onClick={() => history("/blogs/Overzicht+van+de+dagen")}
          >
            ‘(Dagboek bij) de Najaarsfeesten’
          </DagboekHeadingLink>
        </DagboekHeading>
      </Text>
    )
  );
};
