import React, { useEffect, useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import nlLocale from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import listPlugin from "@fullcalendar/list";

import styled from "styled-components";

import tw from "twin.macro";
import MainFeature from "engine/MainFeature";
import { PageContent, Text as BlogText } from "pages/ContentPage";
import {
  ContentWithPaddingLg,
  ContentWithPaddingMd,
} from "components/misc/Layouts";
import { useNavigate } from "react-router-dom";
import "./DagboekCSS.css";
import useWindowDimensions from "engine/UseWindowDimensions";
import { Query, Timestamp, collection, query, where } from "firebase/firestore";

const Subtitle = tw.h3`mt-4  text-black text-left text-xl sm:text-2xl lg:text-3xl text-center md:text-left leading-tight`;
const FullImg = tw.img`rounded-full object-cover gap-2 w-12 h-12 shadow-md md:block hidden`;
const CalEvent = styled.div`
  ${tw``} ${(props) => props.generic && tw``}
  ${(props) => props.cisEvent && tw`bg-sky-400 shadow-none text-white`}
`;
const Row = tw.div`flex justify-between items-center`;
const RightPart = tw.div` flex  items-center justify-end`;
const Text = styled.p`
  ${tw`ml-2 whitespace-normal break-words font-bold  block text-center`} {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`;
const AuthorLine = tw.div`flex gap-4 items-center `;
const AuthorImg = tw.img`h-6 w-6 object-cover mt-2 rounded-full shadow-md lg:block hidden`;
const AuthorName = tw.p`sm:block hidden mt-2 mr-2 whitespace-normal break-words`;
const CalenderWrapper = tw.div`mx-auto flex justify-around mt-8`;

export function formatDate(inputDate) {
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Get the month (add 1 because months are zero-indexed)
  const day = inputDate.getDate().toString().padStart(2, "0"); // Get the day

  return `${month}-${day}`;
}

export const renderEventContent = (eventInfo, history) => {
  // console.log(
  //   "source",
  //   eventInfo.event.title,
  //   eventInfo.event.source.internalEventSource.meta.googleCalendarId
  // );
  if (eventInfo.event.title !== "Test parasja")
    return (
      <div
        className={`break-all p-2 bg-sky-400 rounded-lg shadow-lg flex-col gap-4 break-words border-none outline-none shadow-none cursor-pointer w-full ${
          !eventInfo.event.extendedProps.objid &&
          "bg-transparent shadow-none text-gray-800 h-8"
        }`}
        onClick={() => {
          if (eventInfo.event.extendedProps.objid)
            window.location.href =
              "https://www.hetcis.nl/najaar2023/" +
              formatDate(eventInfo.event.start) +
              ".php";

          console.log(eventInfo.event.extendedProps.idx, eventInfo.event.start);
        }}

        // style={{ backgroundColor: !eventInfo.event.imageurl && "white" }}
      >
        <Row>
          {" "}
          {eventInfo.event.extendedProps.imageurl && (
            <FullImg src={eventInfo.event.extendedProps.imageurl}></FullImg>
          )}
          <Text style={{ wordWrap: "break-word" }} className="break-all">
            {eventInfo.event.title}
          </Text>
        </Row>
        <RightPart>
          <AuthorName>{eventInfo.event.extendedProps.authorname}</AuthorName>
          {eventInfo.event.extendedProps.authorurl && (
            <AuthorImg
              src={eventInfo.event.extendedProps.authorurl}
            ></AuthorImg>
          )}
        </RightPart>
      </div>
    );
};

export default (props) => {
  const history = useNavigate();

  const [newEvents, setNewEvents] = useState();

  useEffect(() => {
    if (props.metadataDoc) {
      const objectList = Object.values(props.metadataDoc.parasjotData);
      const objectListWithId = objectList.map((obj, index) => {
        return { ...obj, idx: index };
      });
      setNewEvents(objectListWithId);
    }
  }, [props.metadataDoc]);

  //Load DB content by current active tab
  //Read params from url for article id and open mode
  const [currentDate, changeDate] = useState(new Date());

  //Load DB content by current active tab
  const dbRef = collection(props.firestore, "documents");
  const buildQuery = query(
    dbRef,
    where("selectedDate", "==", Timestamp.fromDate(currentDate))
  );
  const [article] = useCollection(buildQuery, { idField: "id" });

  if (article)
    if (article.docs.length > 0) {
      console.log(
        article.docs[0].data(),
        currentDate,
        currentDate instanceof Date
      );
    }

  const { width, height } = useWindowDimensions();

  return (
    <div>
      <MainFeature
        heading={"Dagboek Joodse Najaarsfeesten"}
        imageSrc={
          "https://images.unsplash.com/photo-1646270717039-91f7f056952a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
        }
        useExternalImg={true}
        description={
          <div>
            In de Bijbel zijn ervoor in het najaar feest- en vastendagen
            voorgeschreven, die we in de kerk niet kennen. In het jodendom zijn
            ze heel belangrijk. U kunt van dag tot dag lezen hoe deze periode
            beleefd wordt met het Dagboek bij de Joodse najaarsfeesten, dat we u
            graag ook in 2024 weer aanbieden.{" "}
            <a className="text-sky-500" href="www.kerkenisrael.nl/najaar">
              www.kerkenisrael.nl/najaar
            </a>
          </div>
        }
        listItems={[
          "In 2017 schreef ds. Aart Brons een Dagboek bij de Joodse najaarsfeesten. Het doel ervan was mensen te laten meeleven met de Joodse feest- en vastendagen in het najaar: het Joodse Nieuwjaar, de Grote Verzoendag en het Loofhuttenfeest. Voor elke dag van 15 september tot 14 oktober 2017 was er een stukje, en dagelijks ging er een mailtje naar vele adressen om dat onder de aandacht te brengen.",
          "Er kwamen veel enthousiaste reacties op. Ook werd gevraagd of het gepubliceerd zou kunnen worden. Het probleem is dan echter dat de Joodse feesten steeds op andere dagen van de gewone kalender vallen. Daarom werd er in 2018 en 2019 in de feestperiode een mailtje gestuurd met een samenvatting van het materiaal.",
          <div>
            Ook dit jaar gebruiken we een update van het oorspronkelijke
            materiaal. Het gaat nu om de periode van 27 september tot 26
            oktober. Een week voor het begin van het nieuwe Joodse jaar 5786.
            Schrijf u in en krijg elke dag in de genoemde periode direct het
            passende stukje. Je kunt vandaar altijd nog naar de inleiding of
            naar andere dagen gaan. Het dagboek bij de najaarsfeesten:
            <a className="text-sky-500" href="www.kerkenisrael.nl/najaar">
              www.kerkenisrael.nl/najaar
            </a>
          </div>,
        ]}
      ></MainFeature>
      {/* <FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin, listPlugin]}
        initialView={width > 768 ? "dayGridMonth" : "timeGridFourDay"}
        views={{
          timeGridFourDay: {
            type: "list",
            dayCount: 7,
          },
        }}
        locales={[nlLocale]}
        googleCalendarApiKey="AIzaSyApgQvDLkm4bP8LZvhGdVrUCQWhW6IQ-Kk"
        locale="nl"
        eventOrder={"start"}
        eventContent={(i) => renderEventContent(i, history)}
        eventSources={[
          {
            googleCalendarId:
              "9ub3e2c0v093oug40dbct09dggmlpdmj@import.calendar.google.com",
          },
          {
            googleCalendarId: "nl.jewish#holiday@group.v.calendar.google.com",
          },
          {
            googleCalendarId:
              "kcia9kvjvqfpm8r2327olt8qok@group.calendar.google.com",
          },
          {
            googleCalendarId:
              "v6ml1i6os9pi9s6jthmmlpo4is@group.calendar.google.com",
          },
          { googleCalendarId: "centrumvoorisraelstudies@gmail.com" },

          {
            events: newEvents,
          },
        ]}
      /> */}

      {article && article.docs && article.docs.length > 0 && (
        <ContentWithPaddingLg>
          <BlogText>
            <PageContent article={article.docs[0]}></PageContent>
          </BlogText>
        </ContentWithPaddingLg>
      )}
    </div>
  );
};
