import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import sanitize from "sanitize-html";
import sanitizeHtml from "sanitize-html";
import { Timestamp } from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { showNotification } from "@mantine/notifications";

export const createUser = (db, auth, userData, email, password) => {
  // Creates a user account and the users account data based on the provided userdata
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up
      const createdUser = userCredential.user;
      setDoc(doc(db, "users", createdUser.uid), {
        displayName: userData.displayName,
        email: createdUser.email,
        bio: userData.bio,
        website: userData.website,
        photoUrl: userData.photoUrl,
        rights: [],
        rightRequests: [],
        uid: createdUser.uid,
      });
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          // ..
          showNotification({
            title: "Success",
            message: "Account created:" + email,
            color: "green",
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          showNotification({
            title: "Mail Verification Error",
            message: errorMessage,
            color: "red",
          });
        });
    })
    .catch((error) => {
      const errorCode = error?.code;
      const errorMessage = error?.message;
      showNotification({
        title: "User Creation Error",
        message: errorMessage,
        color: "red",
      });
    });
};

export const parseAlgoliaDate = (algoliaDate) => {
  const milliseconds = algoliaDate._seconds
    ? algoliaDate._seconds * 1000 + algoliaDate._nanoseconds / 1000000
    : algoliaDate * 1000;
  return new Date(milliseconds);
};

export const dateToDutchTimeString = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Amsterdam",
  };

  return date.toLocaleString("nl-NL", options);
};

// i.e. 5 January 2024
export const dateToDutchEasyDate = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "Europe/Amsterdam",
  };

  return date.toLocaleString("nl-NL", options);
};

export function toDateTime(secs) {
  var t = new Date(1970, 0, 2); // Epoch
  t.setSeconds(secs);
  return t;
}

// Helper function to convert "DD-MM-YY" to a Date object
export const stringToDate = (str) => {
  const [day, month, year] = str.split("-").map(Number);
  // Note: Month in Date is 0-indexed
  return new Date(year + 2000, month - 1, day);
};

// Function to filter the data by the date range
export const filterDataByDateRange = (data, [startDate, endDate]) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const date = stringToDate(key);
    if (date >= startDate && date <= endDate) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const todayDateOnlyDay = () => {
  var d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.getFullYear() + "_" + (d.getMonth() + 1) + "_" + d.getDate();
};

export function shortenString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "..";
  } else {
    return str;
  }
}

export function daysDiffDates(date1, date2) {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
export function range(start, end) {
  return Array.apply(0, Array(end - 1)).map((element, index) => index + start);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//For sorting the posts by date
export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    //If customdate is given sort by that
    //NOTE: This only sorts per batch, with customdate somethings might be in the wrong batch
    var aProperty = property;
    var bProperty = property;
    if (a.useCostumDate) {
      aProperty = "customDate";
    }
    if (b.useCostumDate) {
      bProperty = "customDate";
    }

    var valA = a[aProperty];
    var valB = b[bProperty];

    if (aProperty === "customDate") {
      console.log("valA before", valA)
      valA = getDateFromTSOrDateOrStr(valA)
      console.log("valA", valA)
    }
    if (bProperty === "customDate") {
      console.log("valB before", valB)
      valB = getDateFromTSOrDateOrStr(valB)
      console.log("valB", valB)
    }

    // Make sure that undefined values are oldest/last to the end
    if (valA == undefined)
      return -1;
    if (valB == undefined)
      return 1;

    var result =
      valA< valB ? -1 : valA > valB ? 1 : 0;

    return result * sortOrder;
  };
}
const datefromString = (dateString) => {
  const parts = dateString?.split(/[- :.]/);
  if (!parts) return;
  return new Date(
    +parts[0],
    +parts[1] - 1,
    +parts[2],
    +parts[3],
    +parts[4],
    +parts[5],
    Math.round(+parts[6] / 1000)
  );
};
export function getDateFromTSOrDateOrStr(variable) {
  if (variable instanceof Timestamp) {
    return variable.toDate();
  } else if (variable instanceof String) {
    return datefromString(variable) || new Date();
  } else if (variable instanceof Date) {
    return variable;
  }
}

export function randRange(min, max) {
  return Math.random() * (max - min) + min;
}

export const SemiRandomColor = (idx) => {
  const items = ["indigo", "cyan", "teal", "lime", "blue", "orange", "red"];
  return items[idx % items.length];
};

export const SemiRandomTWColor = (idx) => {
  const items = [
    "from-lime-400",
    "from-orange-400",
    "from-sky-400",
    "from-yellow-400",
    "from-teal-400",
    "from-neutral-400",
    "from-cyan-400",
    "from-amber-400",
    "from-emerald-400",
    "from-red-400",
  ];
  return items[idx % items.length];
};

// Construct a firestore query from a map of required properties
export function ConstructBaseQuery(q, dbRef, query_items) {
  // Double loop through the keys and their lists (i.e. {authors: ['kees', 'jan'], tags:['cool']})
  for (const key of Object.keys(query_items)) {
    const items_for_key = query_items[key];
    if (items_for_key) {
      for (var i = 0; i < items_for_key.length; i++) {
        //  If no query is formed yet
        if (q === null)
          q = query(
            dbRef,
            where(`${key}.` + String(items_for_key[i]), "==", true)
          );
        // If the query is already formed
        else
          q = query(q, where(`${key}.` + String(items_for_key[i]), "==", true));
      }
    }
  }
  if (q === null) q = dbRef;
  return q;
}

// Post html to post text
export function stripHtml(html) {
  // return sanitizeHtml(html);
  if (html) {
    const regex = /(<([^>]+)>)/gi;
    const result = html.replace(regex, "");
    return result;
  }
  return "";
}

export const isImageLink = (link) => {
  return (
    link.endsWith(".png") ||
    link.endsWith(".jpg") ||
    link.endsWith(".jpeg") ||
    link.endsWith(".gif")
  );
};
